import { useWeb3React } from '@web3-react/core'
import { AddressQRModal } from 'components/AccountDrawer/AddressQRModal'
import ConnectedAccountBlocked from 'components/ConnectedAccountBlocked'
import FiatOnrampModal from 'components/FiatOnrampModal'
import { ReceiveCryptoModal } from 'components/ReceiveCryptoModal'
import useAccountRiskCheck from 'hooks/useAccountRiskCheck'
import { lazy } from 'react'
import { useModalIsOpen } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'

const Bag = lazy(() => import('nft/components/bag/Bag'))

export default function TopLevelModals() {
  const blockedAccountModalOpen = useModalIsOpen(ApplicationModal.BLOCKED_ACCOUNT)
  const { account } = useWeb3React()
  useAccountRiskCheck(account)
  const accountBlocked = Boolean(blockedAccountModalOpen && account)

  return (
    <>
      <ConnectedAccountBlocked account={account} isOpen={accountBlocked} />
      <Bag />
      <FiatOnrampModal />
      {account && <ReceiveCryptoModal />}
      {account && <AddressQRModal accountAddress={account} />}
    </>
  )
}
