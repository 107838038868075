import { opacify } from "./utils";

export const colors = {
  neutralDark: "#121417",
  neutralBase: "#000000",
  neutralMid: "#000000",
  neutralLight: "rgba(18, 20, 23, 1)",
  neutralLighter: "#727D8D",
  neutralBorder: '#2F353A',
  neutralLightest: "#C3CBD6",
  primaryDark: "rgba(70, 255, 244, 0.2)",
  primaryBase: "#46FFF4",
  primaryMid: "#46FFF4",
  primaryLight: "#46FFF4",
  positiveDark: "#16A249",
  positiveBase: "#22C55E",
  positiveMid: "#4ADE80",
  positiveLight: "#85EFAC",
  negativeDark: "#C73D3D",
  negativeBase: "#EF4444",
  negativeMid: "#F26666",
  negativeLight: "#FDA4AF",
  white: "#FFFFFF",
  black: "#000000",
  aquaBlue: "#00FFFF",
  brightBlue: "#15CEF7",
  gray: "#6071AE",
  poorBlue: "#81A1E1",
  violet: "#A589FF",
  royalBlue: "#5B53D8",
  royalBlue10: "rgba(91, 83, 216, 0.1)",
  deepPurple: "#291860",
  lowBlue: "#1F357D",
  deepBlue: "#142970",
  deepBlue50: "rgba(20, 41, 112, 0.5)",
  darkBlue: "#132562",
  downRiver: "#0C1A4A",
  deepCove: "#05113B",
  deepCove42: "rgba(5, 17, 59, 0.42)",
  deepCove80: "rgba(5, 17, 59, 0.8)",
  grayHelp: "#9B9B9B",
  gray50: "#F5F6FC",
  gray100: "#E8ECFB",
  gray150: "#D2D9EE",
  gray200: "#B7B8BA",
  gray250: "#B7B8BA",
  gray300: "#B7B8BA",
  gray350: "#B7B8BA",
  gray400: "#606060",
  gray450: "#606060",
  gray500: "#3D4449",
  gray550: "#272727",
  gray600: "#272727",
  gray650: "#272727",
  gray700: "#272727",
  gray750: "#272727",
  gray800: "#272727",
  gray850: "#202020",
  gray900: "#121212",
  gray950: "#0B0C0C",
  pink50: "#F9ECF1",
  pink100: "#FFD9E4",
  pink200: "#FBA4C0",
  pink300: "#FF6FA3",
  pink400: "#FB118E",
  pink500: "#C41969",
  pink600: "#8C0F49",
  pink700: "#55072A",
  pink800: "#350318",
  pink900: "#2B000B",
  pinkVibrant: "#F51A70",
  red50: "#FAECEA",
  red100: "#FED5CF",
  red200: "#FEA79B",
  red300: "#FD766B",
  red400: "#FA2B39",
  red500: "#C4292F",
  red600: "#891E20",
  red700: "#530F0F",
  red800: "#380A03",
  red900: "#240800",
  redVibrant: "#F14544",
  yellow50: "#F6F2D5",
  yellow100: "#DBBC19",
  yellow200: "#DBBC19",
  yellow300: "#BB9F13",
  yellow400: "#A08116",
  yellow500: "#866311",
  yellow600: "#5D4204",
  yellow700: "#3E2B04",
  yellow800: "#231902",
  yellow900: "#180F02",
  yellowVibrant: "#FAF40A",
  gold200: "#EEB317",
  gold400: "#B17900",
  goldVibrant: "#FEB239",
  green50: "#E3F3E6",
  green100: "#BFEECA",
  green200: "#76D191",
  green300: "#40B66B",
  green400: "#209853",
  green500: "#0B783E",
  green600: "#0C522A",
  green700: "#053117",
  green800: "#091F10",
  green900: "#09130B",
  greenVibrant: "#5CFE9D",
  blue50: "#EDEFF8",
  blue100: "#DEE1FF",
  blue200: "#ADBCFF",
  blue300: "#869EFF",
  blue400: "#4C82FB",
  blue500: "#1267D6",
  blue600: "#1D4294",
  blue700: "#09265E",
  blue800: "#0B193F",
  blue900: "#040E34",
  blueVibrant: "#587BFF",
  magenta300: "#FD82FF",
  magentaVibrant: "#FC72FF",
  purple300: "#8440F2",
  purple900: "#1C0337",
  networkEthereum: "#627EEA",
  networkOptimism: "#FF0420",
  networkOptimismSoft: "rgba(255, 4, 32, 0.16)",
  networkPolygon: "#A457FF",
  networkArbitrum: "#28A0F0",
  networkBsc: "#F0B90B",
  networkPolygonSoft: "rgba(164, 87, 255, 0.16)",
  networkEthereumSoft: "rgba(98, 126, 234, 0.16)",
};

type Theme = typeof darkTheme;

const commonTheme = {
  white: colors.white,
  black: colors.black,
  promotional: colors.magenta300,
  brandedGradient: "linear-gradient(139.57deg, #FF79C9 4.35%, #FFB8E2 96.44%);",
  promotionalGradient:
    "radial-gradient(101.8% 4091.31% at 0% 0%, #4673FA 0%, #9646FA 100%);",
  fillGradient: "linear-gradient(180deg, rgba(19, 37, 98, 0) 0%, #132562 100%)",
  outlineGradientPrimary:
    "linear-gradient(135.27deg, #4866CB -17.16%, rgba(72, 102, 203, 0) 71.02%)",
  outlineGradientSecondary:
    "linear-gradient(152.69deg, #FFFFFF -16.22%, rgba(255, 255, 255, 0) 76.37%)",
  mainGradientPrimary: "linear-gradient(93.95deg, #15B7F7 0%, #8912C4 97.94%)",
  mainGradientSecondary: "linear-gradient(180deg, #15CEF7 0%, #9123C5 100%)",
  mainGradientTertiary:
    "linear-gradient(180deg, #15B7F7 -8.12%, #8912C4 110.58%)",

  borderPrimary: colors.neutralMid,
  borderPrimaryHover: colors.neutralBase,
  borderSecondary: opacify(10, colors.white),

  buttonPrimary: colors.primaryDark,
  buttonPrimaryHover: colors.primaryLight,
  buttonPrimaryText: colors.primaryBase,
  buttonPrimaryTextHover: colors.neutralLight,
  buttonSecondary: colors.primaryBase,
  buttonSecondaryHover: colors.primaryDark,
  buttonSecondaryText: colors.neutralLight,
  buttonDisabled: colors.neutralLight,
  buttonTextDisabled: colors.neutralLightest,

  hoverState: opacify(24, colors.primaryLight),
  hoverDefault: opacify(8, colors.primaryBase),
};

export const darkTheme = {
  ...commonTheme,

  userThemeColor: colors.magentaVibrant,

  background: colors.neutralBase,
  backgroundBackdrop: colors.neutralBase,
  backgroundSurface: colors.gray900,
  backgroundGray: colors.gray850,
  backgroundModule: colors.neutralDark,
  backgroundInteractive: colors.neutralLight,
  backgroundInput: colors.neutralDark,
  backgroundFloating: opacify(12, colors.neutralBase),
  backgroundOutline: opacify(24, colors.neutralBase),
  borderLight: opacify(12, colors.white),
  backgroundScrim: opacify(72, colors.neutralBase),
  backgroundScrolledSurface: opacify(72, colors.neutralBase),
  neutralBorder: colors.neutralBorder,

  iconPrimary: colors.neutralLighter,

  textPrimary: colors.white,
  textSecondary: colors.neutralLighter,
  textTertiary: colors.gray500,

  accentAction: colors.primaryMid,
  accentActive: colors.primaryMid,
  accentSuccess: colors.positiveBase,
  accentWarning: colors.primaryDark,
  accentFailure: colors.negativeMid,
  accentCritical: colors.negativeBase,

  accentActionSoft: opacify(24, colors.primaryMid),
  accentActiveSoft: opacify(24, colors.primaryMid),
  accentSuccessSoft: opacify(24, colors.positiveBase),
  accentWarningSoft: opacify(24, colors.primaryDark),
  accentFailureSoft: opacify(12, colors.negativeMid),

  accentTextDarkPrimary: opacify(80, colors.primaryDark),
  accentTextDarkSecondary: opacify(60, colors.primaryDark),
  accentTextDarkTertiary: opacify(24, colors.primaryDark),

  accentTextLightPrimary: colors.neutralLight,
  accentTextLightSecondary: opacify(72, colors.neutralLight),
  accentTextLightTertiary: opacify(12, colors.neutralLight),

  deepShadow:
    "12px 16px 24px rgba(0, 0, 0, 0.24), 12px 8px 12px rgba(0, 0, 0, 0.24), 4px 4px 8px rgba(0, 0, 0, 0.32);",
  shallowShadow:
    "4px 4px 10px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.12), 1px 2px 2px rgba(0, 0, 0, 0.12);",

  networkDefaultShadow: `0px 40px 120px ${opacify(16, colors.primaryBase)}`,

  stateOverlayHover: opacify(8, colors.gray300),
  stateOverlayPressed: opacify(24, colors.gray200),

  searchBackground: colors.neutralLight,
  searchOutline: `rgba(255,255,255,0.1)`,
};

export const lightTheme: Theme = {
  ...commonTheme,

  userThemeColor: colors.magentaVibrant,

  background: "#faf9fa", 
  backgroundBackdrop: colors.white,
  backgroundSurface: colors.white,
  backgroundGray: colors.gray850,
  backgroundModule: colors.gray50,
  backgroundInteractive: colors.gray100,
  backgroundInput: colors.gray750,
  backgroundFloating: opacify(8, colors.gray700),
  backgroundOutline: colors.gray150,
  backgroundScrim: opacify(60, colors.gray900),
  borderLight: opacify(12, colors.white),
  backgroundScrolledSurface: opacify(72, colors.white),
  neutralBorder: colors.neutralBorder,

  iconPrimary: colors.primaryMid,

  textPrimary: colors.gray900,
  textSecondary: colors.gray400,
  textTertiary: colors.gray300,

  accentAction: colors.pink400,
  accentActive: colors.primaryMid,
  accentSuccess: colors.green300,
  accentWarning: colors.gold400,
  accentFailure: colors.red400,
  accentCritical: colors.red400,

  accentActionSoft: opacify(12, colors.pink400),
  accentActiveSoft: opacify(24, colors.primaryMid),
  accentSuccessSoft: opacify(24, colors.green300),
  accentWarningSoft: opacify(24, colors.gold400),
  accentFailureSoft: opacify(12, colors.red400),

  accentTextDarkPrimary: opacify(80, colors.gray900),
  accentTextDarkSecondary: opacify(60, colors.gray900),
  accentTextDarkTertiary: opacify(24, colors.gray900),

  accentTextLightPrimary: colors.gray50,
  accentTextLightSecondary: opacify(72, colors.gray50),
  accentTextLightTertiary: opacify(12, colors.gray50),

  deepShadow:
    "8px 12px 20px rgba(51, 53, 72, 0.04), 4px 6px 12px rgba(51, 53, 72, 0.02), 4px 4px 8px rgba(51, 53, 72, 0.04);",
  shallowShadow:
    "6px 6px 10px rgba(51, 53, 72, 0.01), 2px 2px 6px rgba(51, 53, 72, 0.02), 1px 2px 2px rgba(51, 53, 72, 0.02);",

  networkDefaultShadow: `0px 40px 120px ${opacify(12, colors.pink400)}`,

  stateOverlayHover: opacify(8, colors.gray300),
  stateOverlayPressed: opacify(24, colors.gray200),

  searchBackground: opacify(4, colors.white),
  searchOutline: opacify(1, colors.black),
};
